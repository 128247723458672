<template>
  <div>
    <h1>Criar Regra</h1>

    <create-form @save="save" />

  </div>
</template>

<script>

import CreateForm from './CreateForm'

  export default {
    components: {
      CreateForm,
    },
    methods: {
      save(form) {
        this.$http.post('api/collect-partner-rules', form)
        .then(() => {
          this.$side({
            type    : 'success',
            msg     : 'Salvo com sucesso',
            duration: 4000
          });
        }).catch(err => {
          console.log(err);

          this.$side({
            type    : 'error',
            msg     : 'Erro ao salvar',
            duration: 4000
          });
        });
      }
    },
  }
</script>

<style scoped>

</style>