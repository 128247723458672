<template>
  <div>
    <v-form @submit.prevent="save" ref="formCreateCollectPartnerRule">
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Nome do cliente*"
            v-model="form.partner_name"
            :rules="[v => !! v || 'Este campo é obrigatório']"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-text-field
            label="Porcentagem"
            v-model="form.percentage"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <v-text-field
            label="Frete Mínimo"
            v-model="form.min_freight"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <v-switch
            label="Desconto ICMS"
            v-model="form.icms"
          ></v-switch>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-btn color="success" type="submit">Salvar</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        form: {
          partner_name: null,
          percentage: null,
          icms: false,
          min_freight: null,
        }
      }
    },
    methods: {
      save() {
        if(this.$refs.formCreateCollectPartnerRule.validate()) {
          this.$emit('save', this.form);
        }
      }
    },
  }
</script>

<style scoped>

</style>